import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { CButton } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilExpandDown, cilExpandUp } from "@coreui/icons";

export const ExportToExcel = ({ apiData, fileName, exportFunction }) => {
  // const fileType =
  //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  // const fileExtension = ".xlsx";

  // const exportToCSV = (apiData, fileName) => {
  //   const headers = [
  //     "No.",
  //     "Nama",
  //     "Absen Pagi",
  //     "Lokasi",
  //     "Koordinat",
  //     "Telat",
  //     "Dikonfirmasi",
  //     "Absen Siang",
  //     "Lokasi",
  //     "Koordinat",
  //     "Telat",
  //     "Dikonfirmasi",
  //     "Absen Pulang",
  //     "Lokasi",
  //     "Koordinat",
  //     "Dikonfirmasi",
  //     "Tanggal",
  //   ]; // Replace with your desired headers
  //   const dataAsArray = apiData.map((item) => [
  //     item.id,
  //     item.user.name,
  //     item.check_in,
  //     item.check_in_location,
  //     item.check_in_cordinate,
  //     item.check_in_late,
  //     item.check_in_approved,
  //     item.middle_of_day,
  //     item.middle_of_day_location,
  //     item.middle_of_day_cordinate,
  //     item.middle_of_day_late,
  //     item.middle_of_day_approved,
  //     item.check_out,
  //     item.check_out_location,
  //     item.check_out_cordinate,
  //     item.check_out_approved,
  //     item.created_at,
  //   ]);
  //   const dataWithHeaders = [headers, ...dataAsArray];

  //   const ws = XLSX.utils.aoa_to_sheet(dataWithHeaders);
  //   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //   const data = new Blob([excelBuffer], { type: fileType });
  //   FileSaver.saveAs(data, fileName + fileExtension);
  // };

  return (
    <CButton
      color="warning"
      className="text-white px-4 py-2"
      onClick={(e) => exportFunction()}
    >
      <CIcon icon={cilExpandUp} /> Export
    </CButton>
  );
};
