import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  CBadge,
  CNavGroup,
  CNavItem,
  CNavTitle,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler,
} from "@coreui/react";
import { NavLink, useNavigate } from "react-router-dom";
import CIcon from "@coreui/icons-react";
// import logo from "../assets/JAKI.png";
import { cilClipboard, cilHome, cilReportSlash } from "@coreui/icons";

const AppSidebar = () => {
  let navigationTo = useNavigate();

  const dispatch = useDispatch();
  const unfoldable = useSelector((state) => state.sidebarUnfoldable);
  const sidebarShow = useSelector((state) => state.sidebarShow);
  const [activeMenu, setActiveMenu] = useState("dashboard");

  return (
    <CSidebar
      position="fixed bg-white"
      className="main-sidebar"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: "set", sidebarShow: visible });
      }}
    >
      <CSidebarBrand
        className="d-none d-md-flex bg-white"
        onClick={() => navigationTo("/")}
        style={{ cursor: "pointer" }}
      >
        <h2 style={{ color: "#ffb703", fontWeight: "bold" }}>SIKADIR</h2>
      </CSidebarBrand>
      <CSidebarNav
        className="rounded-3 d-flex flex-column gap-2"
        style={{ backgroundColor: "#fff" }}
      >
        <CNavTitle
          className="d-flex flex-row align-items-center gap-2"
          style={{ color: "rgba(0, 0, 0, 0.5)" }}
        >
          Main Menu
          <div
            style={{ border: "1px solid rgba(0, 0, 0, 0.5)", width: "50%" }}
          ></div>
        </CNavTitle>
        <NavLink
          onClick={() => setActiveMenu("dashboard")}
          to="/"
          className={`text-decoration-none py-3 navv-item ${
            activeMenu === "dashboard" ? "activedNav" : ""
          }`}
        >
          <CNavItem
            className={`d-flex align-items-center ${
              activeMenu === "dashboard" ? "activedTeks" : ""
            }`}
            // style={{ backgroundColor: "rgba(135, 193, 89, 0.2)" }}
          >
            <CIcon className="mx-3" size="lg" icon={cilClipboard} />
            Absensi
          </CNavItem>
        </NavLink>
        <NavLink
          onClick={() => setActiveMenu("record")}
          to="/data"
          className={`text-decoration-none py-3 navv-item ${
            activeMenu === "record" ? "activedNav" : ""
          }`}
        >
          <CNavItem
            className={`d-flex align-items-center ${
              activeMenu === "record" ? "activedTeks" : ""
            }`}
            // style={{ backgroundColor: "rgba(135, 193, 89, 0.2)" }}
          >
            <CIcon className="mx-3" size="lg" icon={cilClipboard} />
            Rekapan Absensi
          </CNavItem>
        </NavLink>
        <NavLink
          onClick={() => setActiveMenu("holidays")}
          to="/holidays"
          className={`text-decoration-none py-3 navv-item ${
            activeMenu === "holidays" ? "activedNav" : ""
          }`}
        >
          <CNavItem
            className={`d-flex align-items-center ${
              activeMenu === "holidays" ? "activedTeks" : ""
            }`}
          >
            <CIcon className="mx-3" size="lg" icon={cilClipboard} />
            Hari Libur
          </CNavItem>
        </NavLink>
        <NavLink
          onClick={() => setActiveMenu("employee")}
          to="/employee"
          className={`text-decoration-none py-3 navv-item ${
            activeMenu === "employee" ? "activedNav" : ""
          }`}
        >
          <CNavItem
            className={`d-flex align-items-center ${
              activeMenu === "employee" ? "activedTeks" : ""
            }`}
          >
            <CIcon className="mx-3" size="lg" icon={cilClipboard} />
            Data Karyawan
          </CNavItem>
        </NavLink>
        <NavLink
          onClick={() => setActiveMenu("leave")}
          to="/leave"
          className={`text-decoration-none py-3 navv-item ${
            activeMenu === "leave" ? "activedNav" : ""
          }`}
        >
          <CNavItem
            className={`d-flex align-items-center ${
              activeMenu === "leave" ? "activedTeks" : ""
            }`}
          >
            <CIcon className="mx-3" size="lg" icon={cilClipboard} />
            Data Izin / Cuti
          </CNavItem>
        </NavLink>
        <NavLink
          onClick={() => setActiveMenu("location")}
          to="/location"
          className={`text-decoration-none py-3 navv-item ${
            activeMenu === "location" ? "activedNav" : ""
          }`}
        >
          <CNavItem
            className={`d-flex align-items-center ${
              activeMenu === "location" ? "activedTeks" : ""
            }`}
          >
            <CIcon className="mx-3" size="lg" icon={cilClipboard} />
            Data Lokasi
          </CNavItem>
        </NavLink>
        {/* <CNavTitle className="m-0">UMKM/UKM dan Koperasi</CNavTitle>
      <CNavGroup toggler="Data Usaha">
        <NavLink
          to="/dashboard/umkm/dataidentitas"
          className="text-decoration-none navv-item"
          style={{ color: "#ffffff99" }}
        >
          <CNavItem className="d-flex align-items-center text-white py-3">
            <CIcon className="mx-3" size="lg" icon={cilDescription} />
            Identitas Usaha
          </CNavItem>
        </NavLink>
        <NavLink
          to="/dashboard/umkm/identitaskoperasi"
          className="text-decoration-none navv-item"
          style={{ color: "#ffffff99" }}
        >
          <CNavItem className="d-flex align-items-center text-white py-3">
            <CIcon className="mx-3" size="lg" icon={cilDescription} />
            Identitas Koperasi
          </CNavItem>
        </NavLink>
      </CNavGroup>
      <CNavGroup toggler="Laporan">
        <NavLink
          to="/dashboard/umkm/laporanumkm"
          className="text-decoration-none navv-item"
          style={{ color: "#ffffff99" }}
        >
          <CNavItem className="d-flex align-items-center text-white py-3">
            <CIcon
              className="mx-3 text-white"
              size="lg"
              icon={cilDescription}
            />
            Laporan UMKM
          </CNavItem>
        </NavLink>
        <NavLink
          to="/dashboard/umkm/laporankoperasi"
          className="text-decoration-none navv-item"
          style={{ color: "#ffffff99" }}
        >
          <CNavItem className="d-flex align-items-center text-white py-3">
            <CIcon
              className="mx-3 text-white"
              size="lg"
              icon={cilDescription}
            />
            Laporan Koperasi
          </CNavItem>
        </NavLink>
      </CNavGroup> */}
      </CSidebarNav>
    </CSidebar>
  );
};

export default AppSidebar;
