import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./scss/style.scss";
import DefaultLayout from "./layout/DefaultLayout";
import Dashboard from "./views/Absensi";
import Login from "./views/Login";
const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },

  {
    path: "*",
    element: <DefaultLayout />,
  },
]);
const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
