import axios from "axios";
// https://siimpaladminbackend.metromatika.com/
// http://localhost:3001/
const instance = axios.create({
  baseURL: "https://sikadir-admin.metromatika.com/",
  // baseURL: "http://localhost:3001/",
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;
