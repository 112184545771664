import React, { useState } from "react";
// import kotamedan from "../assets/kotamedan.png";
// import medanberkah from "../assets/medanberkah.png";
import { CCard, CFormInput } from "@coreui/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { loginAdmin } from "apis/axios";
import LoginAlert from "components/LoginAlert";
function Login() {
  let navigate = useNavigate();
  const [error, setError] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = async () => {
    let userData = {
      email: email,
      password: password,
    };
    try {
      await loginAdmin(userData).then(async (res) => {
        if (res.status === 200) {
          await localStorage.setItem("Token", res.data.token);
          navigate("/");
        } else {
          setError("Email or password is incorrect");
        }
      });
    } catch (error) {
      setError("Pastikan Email Dan Password Terisi dengan benar");
    }
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {error && <LoginAlert message={error} />}
      <div className="col-12">
        {/* <img src={kotamedan} alt="" style={{ marginRight: 20, padding: 20 }} />
        <img src={medanberkah} alt="" /> */}
      </div>
      <div className="d-flex col-12 align-items-center justify-content-center flex-column">
        <h1 style={{ fontWeight: 800, color: "#ffb703" }}>
          WELCOME TO SIKADIR
        </h1>
        <h4 style={{ fontWeight: 600 }}>Silahkan Login Untuk Melanjutkan</h4>
      </div>
      <div className="d-flex col-12 align-items-center justify-content-center">
        <CCard
          className="p-5 d-flex gap-3 rounded-3"
          style={{
            border: "1px solid rgba(5, 130, 64, 0.5)",
            boxShadow: "0 2px 14px rgba(0, 0, 0, 0.2)",
          }}
        >
          <div>
            <label style={{ color: "#ffb703", fontWeight: 700 }}>Email</label>
            <CFormInput
              type="email"
              placeholder="email"
              value={email}
              onChange={handleEmailChange}
              style={{
                border: "1px solid rgba(5, 130, 64, 0.5)",
                boxShadow: "0 2px 14px rgba(0, 0, 0, 0.2)",
              }}
            ></CFormInput>
          </div>
          <div>
            <label style={{ color: "#ffb703", fontWeight: 700 }}>
              Password
            </label>
            <CFormInput
              type="password"
              placeholder="password"
              value={password}
              onChange={handlePasswordChange}
              style={{
                border: "1px solid rgba(5, 130, 64, 0.5)",
                boxShadow: "0 2px 14px rgba(0, 0, 0, 0.2)",
              }}
            ></CFormInput>
          </div>
          <button
            className="rounded-2 text-white py-2"
            onClick={handleLogin}
            style={{
              backgroundColor: "#ffb703",
              border: 0,
              marginTop: 50,
            }}
          >
            Login
          </button>
        </CCard>
      </div>
    </div>
  );
}

export default Login;
