import axios from "../services/Axios";

export const loginAdmin = async (data) => {
  let headers = {
    "Content-Type": "application/json",
  };
  const res = await axios.post("api/auth/login", data, { headers });
  return res;
};
export const getAbsensi = async (params) => {
  try {
    const res = await axios.get("/api/attendances/get", { params });
    return res;
  } catch (error) {
    throw error;
  }
};
export const checkInConfirmation = async (id) => {
  let headers = {
    "Content-Type": "application/json",
  };
  const res = await axios.put(`api/attendances/${id}/checkin`, { headers });
  return res;
};
export const middleDayConfirmation = async (id) => {
  let headers = {
    "Content-Type": "application/json",
  };
  const res = await axios.put(`api/attendances/${id}/middleofday`, { headers });
  return res;
};
export const checkOutConfirmation = async (id) => {
  let headers = {
    "Content-Type": "application/json",
  };
  const res = await axios.put(`api/attendances/${id}/checkout`, { headers });
  return res;
};
export const getHolidays = async () => {
  let headers = {
    "Content-Type": "application/json",
  };
  const res = await axios.get("api/holiday/get", { headers });
  return res;
};
export const getLocation = async () => {
  let headers = {
    "Content-Type": "application/json",
  };
  const res = await axios.get("api/location/get", { headers });
  return res;
};
export const getLeaves = async () => {
  let headers = {
    "Content-Type": "application/json",
  };
  const res = await axios.get("api/leaves/get", { headers });
  return res;
};
export const createHolidays = async (data) => {
  let headers = {
    "Content-Type": "application/json",
  };
  const res = await axios.post("api/holiday/create", data, { headers });
  return res;
};
export const createLocation = async (data) => {
  let headers = {
    "Content-Type": "application/json",
  };
  const res = await axios.post("api/location/create", data, { headers });
  return res;
};
export const getUsers = async (params) => {
  let headers = {
    "Content-Type": "application/json",
  };
  const res = await axios.get("api/user/get", { params });
  return res;
};
export const updateUser = async (id, data) => {
  let headers = {
    "Content-Type": "application/json",
  };
  const res = await axios.put(`api/user/${id}`, data, headers);
  return res;
};
export const deleteUser = async (id, data) => {
  let headers = {
    "Content-Type": "application/json",
  };
  const res = await axios.put(`api/user/${id}/inactive`, data, headers);
  return res;
};
export const deleteLeave = async (id) => {
  let headers = {
    "Content-Type": "application/json",
  };
  const res = await axios.delete(`api/leaves/${id}`, headers);
  return res;
};
