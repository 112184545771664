import { cilBook, cilExpandDown, cilLevelUp } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCard,
  CCol,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import AttendanceTable from "components/ReportTable/AttendanceTable";
import ReportData from "components/ReportTable/ReportData";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import pic1 from "../assets/images/12.jpg";
import { ExportToExcel } from "../components/ExportToExcel";
import { getAbsensi } from "apis/axios";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
const AttendanceRecord = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModalLocation, setShowModalLocation] = useState(false);
  const [dataAttendance, setDataAttendance] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // Tambahkan state currentPage
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const [sizePage, setSizePage] = useState(10);
  const fileName = "rekapanabsensi";
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const getDataAttendances = async () => {
    try {
      let queryParams = {
        page,
        limit: sizePage,
        startDate,
        endDate,
        month: selectedMonth,
        year: selectedYear,
      };
      if (searchInput) {
        queryParams.name = searchInput; // Add the search input to the query parameters
      }
      const response = await getAbsensi(queryParams);
      setDataAttendance(response.data.rows);
      setCurrentPage(response.data.page);
      setTotalPages(Math.ceil(response.data.count / 10));
      setCount(response.data.count);
    } catch (error) {
      console.error(error);
    }
  };
  const handlePageChange = (page) => {
    setPage(page);
  };
  const handleRows = (perPage) => {
    setSizePage(perPage);
  };
  useEffect(() => {
    getDataAttendances();
  }, [
    page,
    sizePage,
    startDate,
    endDate,
    selectedMonth,
    selectedYear,
    searchInput,
  ]);

  function formatDate(date) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(date).toLocaleDateString("id-ID", options);
    return formattedDate;
  }
  function formatTime(date) {
    const options = {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZone: "Asia/Jakarta",
    };

    const formattedTime = new Date(date).toLocaleString(
      "id-ID", // Indonesian locale
      options
    );

    return formattedTime;
  }

  const columns = [
    {
      name: "Tanggal",
      center: true,
      wrap: true,
      width: "120px",
      selector: (row) => formatDate(row.created_at),
      sortable: true,
    },
    {
      name: "Nama",
      wrap: true,
      width: "120px",
      selector: (row) =>
        row.user && row.user.name ? row.user.name : "Akun User Telah Dihapus",
      sortable: true,
    },

    {
      name: "Absen Pagi",

      selector: (row) => formatTime(row.check_in),
      sortable: true,
    },
    {
      name: "Lokasi",

      selector: (row) => row.check_in_location,
      sortable: true,
    },
    {
      name: "Absen Siang",

      selector: (row) => formatTime(row.middle_of_day),
      sortable: true,
    },

    {
      name: "Absen Pulang",

      selector: (row) => formatTime(row.check_out),
      sortable: true,
    },
    {
      name: "Lokasi",

      selector: (row) => row.check_out_location,
      sortable: true,
    },
    {
      name: "Koordinat",
      selector: (row) => row.check_in_cordinate,
      sortable: true,
    },
  ];
  const customStyles = {
    rows: {
      style: {
        minHeight: "10px",
        padding: "20px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        backgroundColor: "#ffb703",
        color: "#fff",
        fontSize: "15px",
        fontWeight: "600",
      },
    },
    cells: {
      style: {
        padding: "5px",
        fontSize: "15px",
        fontWeight: "600",
      },
    },
  };
  const exportToCSV = async () => {
    try {
      let allData = [];
      let currentPage = 1;
      let totalPages = 1;

      while (currentPage <= totalPages) {
        let queryParams = {
          page: currentPage,
          startDate,
          endDate,
          sizePage,
          month: selectedMonth,
          year: selectedYear,
        };
        if (searchInput) {
          queryParams.name = searchInput; // Add the search input to the query parameters
        }
        const response = await getAbsensi(queryParams);

        allData = [...allData, ...response.data.rows];
        totalPages = Math.ceil(response.data.count / sizePage);
        currentPage++;
      }

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      function formatDate(date) {
        if (!date) return ""; // Handle null or undefined date

        const dateObject = new Date(date);
        const day = String(dateObject.getDate()).padStart(2, "0");
        const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const year = dateObject.getFullYear();

        return `${day}-${month}-${year}`;
      }
      function formatTime(date) {
        const options = {
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          timeZone: "Asia/Jakarta",
        };
        const formattedTime = new Date(date).toLocaleTimeString(
          "id-ID",
          options
        );
        return formattedTime;
      }
      const headers = [
        "No.",
        "Tanggal",
        "Nama",
        "Absen Pagi",
        "Lokasi",
        "Koordinat",
        "Telat",
        "Dikonfirmasi",
        "Absen Siang",
        "Lokasi",
        "Koordinat",
        "Telat",
        "Dikonfirmasi",
        "Absen Pulang",
        "Lokasi",
        "Koordinat",
        "Dikonfirmasi",
      ]; // Replace with your desired headers
      const dataAsArray = allData.map((item, index) => [
        index + 1,
        formatDate(item.created_at),
        item.user ? item.user.name : "User Tidak Ada",
        formatTime(item.check_in),
        item.check_in_location,
        item.check_in_cordinate,
        item.check_in_late,
        item.check_in_approved,
        formatTime(item.middle_of_day),
        item.middle_of_day_location,
        item.middle_of_day_cordinate,
        item.middle_of_day_late,
        item.middle_of_day_approved,
        formatTime(item.check_out),
        item.check_out_location,
        item.check_out_cordinate,
        item.check_out_approved,
      ]);
      const dataWithHeaders = [headers, ...dataAsArray];

      const ws = XLSX.utils.aoa_to_sheet(dataWithHeaders);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch (error) {
      console.error(error);
    }
  };
  const handleSearchInputChange = (e) => {
    const input = e.target.value;
    setSearchInput(input);

    // Filter the data based on the search input
    const filtered = dataAttendance
      ? dataAttendance.filter((item) =>
          item.user.name.toLowerCase().includes(input.toLowerCase())
        )
      : dataAttendance;
    setFilteredData(filtered);
  };
  const tableData = searchInput ? filteredData : dataAttendance;

  return (
    <div className="container">
      <CCard className="px-2 py-2">
        <h5 className="my-3" style={{ fontWeight: 800 }}>
          REKAPAN ABSENSI
        </h5>
        <CCol
          xs={12}
          className="flex-row d-flex gap-4 my-3 justify-content-around"
        >
          <div className="col-2">
            <label>Tanggal Awal:</label>
            <input
              className="form-control"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="col-2">
            <label>Tanggal Akhir:</label>
            <input
              className="form-control"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className="col-2">
            <label>Bulan :</label>
            <input
              className="form-control"
              type="number"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
            />
          </div>
          <div className="col-2">
            <label>Tahun :</label>
            <input
              className="form-control"
              type="year"
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
            />
          </div>
          <div className="col-2">
            <label>Cari Nama:</label>
            <input
              className="form-control"
              type="text"
              placeholder="Cari Nama"
              value={searchInput}
              onChange={handleSearchInputChange}
            />
          </div>
        </CCol>
        <div className="d-flex justify-content-end">
          <div className="col-2 d-flex my-3">
            <ExportToExcel
              exportFunction={exportToCSV}
              apiData={dataAttendance}
              fileName={fileName}
            />
          </div>
        </div>

        <DataTable
          striped
          pagination
          paginationServer
          paginationTotalRows={count}
          onChangePage={handlePageChange}
          columns={columns}
          data={dataAttendance}
          onChangeRowsPerPage={handleRows} // Menggunakan fungsi handleRows untuk mengatur jumlah baris per halaman
          paginationPerPage={sizePage} // Jumlah baris per halaman yang diatur dalam state sizePage
          customStyles={customStyles}
        />
      </CCard>
    </div>
  );
};

export default AttendanceRecord;
