import {
  CButton,
  CCard,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from "@coreui/react";
import { deleteUser, getUsers, updateUser } from "apis/axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";

const Employee = () => {
  const [dataEmployees, setDataEmployees] = useState();
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const handleEditClick = (employee) => {
    setSelectedEmployee(employee);
    setEditModalOpen(true);
  };
  const getDataUsers = async () => {
    let queryParams = {
      name: searchInput ? searchInput : "",
    };
    await getUsers(queryParams)
      .then(async (res) => {
        // setDataHolidays(res.res.res);
        setDataEmployees(res.data.data);
      })
      .catch((error) => {
        // Tangani error di sini
        console.error(error);
      });
  };
  const handleSearchInputChange = (e) => {
    const input = e.target.value;
    setSearchInput(input);
    setPage(1);
    getDataUsers();
  };
  const handleEditSubmit = async () => {
    try {
      if (selectedEmployee) {
        await updateUser(selectedEmployee.id, selectedEmployee);
        setEditModalOpen(false);
        setSelectedEmployee(null);
        getDataUsers();
      }
    } catch (error) {
      console.error("Error updating employee:", error);
    }
  };

  const handleDeleteClick = (employee) => {
    setSelectedEmployee(employee);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      if (selectedEmployee) {
        await deleteUser(selectedEmployee.id);
        setDeleteModalOpen(false);
        setSelectedEmployee(null);
        getDataUsers();
      }
    } catch (error) {
      console.error("Error deleting employee:", error);
    }
  };
  const columns = [
    {
      name: "No",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "50px",
    },
    {
      name: "Nama",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "No.Telephone",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Device ID",
      selector: (row) => row.device_id,
      sortable: true,
      wrap: true,
    },
    {
      name: "Edit",
      center: true,
      wrap: true,
      cell: (row) => (
        <CButton
          color="info"
          className="text-white text-bold"
          size="sm"
          onClick={() => handleEditClick(row)}
        >
          Edit
        </CButton>
      ),
      button: true,
    },
    {
      name: "Delete",
      center: true,
      wrap: true,
      cell: (row) => (
        <CButton
          color="danger"
          size="sm"
          onClick={() => handleDeleteClick(row)}
        >
          Delete
        </CButton>
      ),
      button: true,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "10px",
        padding: "20px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        backgroundColor: "#ffb703",
        color: "#fff",
        fontSize: "15px",
        fontWeight: "600",
      },
    },
    cells: {
      style: {
        padding: "5px",
        fontSize: "15px",
        fontWeight: "600",
      },
    },
  };

  useEffect(() => {
    getDataUsers();
  }, [searchInput]);
  return (
    <div className="container">
      <CCard className="px-2 py-2">
        <h5 className="" style={{ fontWeight: 800 }}>
          DATA PEGAWAI
        </h5>
        <div className="col-12 d-flex justify-content-end">
          <div className="col-3 m-2">
            <label>Cari Nama:</label>
            <input
              className="form-control"
              type="text"
              placeholder="Cari Nama"
              value={searchInput}
              onChange={handleSearchInputChange}
            />
          </div>
        </div>
        <DataTable
          // defaultSortField="name"
          striped
          pagination
          // paginationServer
          // paginationTotalRows={count}
          // subHeaderComponent={subHeaderComponent}
          // subHeader
          // onChangePage={handlePageChange}
          columns={columns}
          data={dataEmployees}
          // onChangeRowsPerPage={handlePerRowsChange}
          // paginationPerPage={pageSize}
          customStyles={customStyles}
        />
      </CCard>
      <CModal visible={editModalOpen} onClose={() => setEditModalOpen(false)}>
        <CModalHeader>Edit Employee</CModalHeader>
        <CModalBody>
          {/* Render edit form fields here */}
          {/* Example: */}
          <label>Nama</label>
          <input
            className="form-control"
            type="text"
            value={selectedEmployee ? selectedEmployee.name : ""}
            onChange={(e) =>
              setSelectedEmployee({ ...selectedEmployee, name: e.target.value })
            }
          />
          <label className="mt-3">Email</label>
          <input
            className="form-control"
            type="text"
            value={selectedEmployee ? selectedEmployee.email : ""}
            onChange={(e) =>
              setSelectedEmployee({
                ...selectedEmployee,
                email: e.target.value,
              })
            }
          />
          <label className="mt-3">No. Telephone</label>
          <input
            className="form-control"
            type="text"
            value={selectedEmployee ? selectedEmployee.phone : ""}
            onChange={(e) =>
              setSelectedEmployee({
                ...selectedEmployee,
                phone: e.target.value,
              })
            }
          />
          <label className="mt-3">Device ID</label>

          <input
            className="form-control"
            type="text"
            value={selectedEmployee ? selectedEmployee.device_id : ""}
            onChange={(e) =>
              setSelectedEmployee({
                ...selectedEmployee,
                device_id: e.target.value,
              })
            }
          />
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setEditModalOpen(false)}>
            Cancel
          </CButton>
          <CButton color="primary" onClick={handleEditSubmit}>
            Save
          </CButton>
        </CModalFooter>
      </CModal>
      <CModal
        visible={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      >
        <CModalHeader>Confirm Delete</CModalHeader>
        <CModalBody>
          Are you sure you want to delete {selectedEmployee?.name}?
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setDeleteModalOpen(false)}>
            Cancel
          </CButton>
          <CButton color="danger" onClick={handleDeleteConfirm}>
            Delete
          </CButton>
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default Employee;
