import { cilCloudDownload } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCard,
  CCol,
  CForm,
  CFormInput,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import {
  createHolidays,
  deleteLeave,
  getHolidays,
  getLeaves,
} from "apis/axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { ExportToExcel } from "components/ExportToExcel";
const Leave = () => {
  let navigate = useNavigate();
  const [dataLeaves, setDataLeaves] = useState();
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const fileName = "Rekapan Cuti";
  const getDataLeaves = async () => {
    await getLeaves()
      .then(async (res) => {
        // setDataLeaves(res.res.res);
        setDataLeaves(res.data.data);
      })
      .catch((error) => {
        // Tangani error di sini
        console.error(error);
      });
  };
  console.log(dataLeaves);
  const handleDeleteConfirm = async () => {
    try {
      if (selectedEmployee) {
        await deleteLeave(selectedEmployee.id);
        setDeleteModalOpen(false);
        setSelectedEmployee(null);
        getDataLeaves();
      }
    } catch (error) {
      console.error("Error deleting employee:", error);
    }
  };
  const exportToCSV = async () => {
    try {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      function formatDate(date) {
        if (!date) return ""; // Handle null or undefined date

        const dateObject = new Date(date);
        const day = String(dateObject.getDate()).padStart(2, "0");
        const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const year = dateObject.getFullYear();

        return `${day}-${month}-${year}`;
      }
      function formatTime(date) {
        const options = {
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          timeZone: "Asia/Jakarta",
        };
        const formattedTime = new Date(date).toLocaleTimeString(
          "id-ID",
          options
        );
        return formattedTime;
      }
      const headers = [
        "No.",
        "Nama",
        "Tanggal Awal Cuti",
        "Tanggal Akhir Cuti",
        "Keterangan",
      ]; // Replace with your desired headers
      const dataAsArray = dataLeaves.map((item, index) => [
        index + 1,
        item.user ? item.user.name : "User Tidak Ada",
        formatDate(item.start_date),
        formatTime(item.end_date),
        item.reason.name,
      ]);
      const dataWithHeaders = [headers, ...dataAsArray];

      const ws = XLSX.utils.aoa_to_sheet(dataWithHeaders);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch (error) {
      console.error(error);
    }
  };
  const handleDeleteClick = (employee) => {
    setSelectedEmployee(employee);
    setDeleteModalOpen(true);
  };
  function formatDate(date) {
    if (!date) return ""; // Handle null or undefined date

    const dateObject = new Date(date);
    const day = String(dateObject.getDate()).padStart(2, "0");
    const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = dateObject.getFullYear();

    return `${day}-${month}-${year}`;
  }
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      width: "50px",
    },
    {
      name: "Nama",
      selector: (row) => (row.user?.name ? row.user.name : "User Tidak Valid"),
      sortable: true,
    },
    {
      name: "Durasi",
      selector: (row) =>
        `${formatDate(row.start_date)} - ${formatDate(row.end_date)}`,
      sortable: true,
    },
    {
      name: "Keterangan",
      selector: (row) => (row.reason?.name ? row.reason.name : ""),
      sortable: true,
    },
    {
      name: "Bukti",
      cell: (row) => (
        <>
          <a
            style={{ textDecoration: "none", color: "black" }}
            href={`http://103.116.168.147:3030/${row.proof[0].filename}`}
            target="_blank"
          >
            <div
              style={{
                border: "1px solid #ffb703",
                borderRadius: 5,
                backgroundColor: "#f7e0a7",
                padding: 7,
                cursor: "pointer",
              }}
            >
              <CIcon icon={cilCloudDownload} />
            </div>
          </a>
        </>
      ),
      // selector: (row) => row.,
      sortable: true,
    },
    {
      name: "Delete",
      center: true,
      wrap: true,
      cell: (row) => (
        <CButton
          color="danger"
          size="sm"
          onClick={() => handleDeleteClick(row)}
        >
          Delete
        </CButton>
      ),
      button: true,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "10px",
        padding: "20px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        backgroundColor: "#ffb703",
        color: "#fff",
        fontSize: "15px",
        fontWeight: "600",
      },
    },
    cells: {
      style: {
        padding: "5px",
        fontSize: "15px",
        fontWeight: "600",
      },
    },
  };

  useEffect(() => {
    getDataLeaves();
  }, []);

  return (
    <div className="container">
      <CCard className="px-2 py-2">
        <h5 className="my-3" style={{ fontWeight: 800 }}>
          DATA IZIN KARYAWAN
        </h5>
        <div className="d-flex justify-content-end">
          <div className="col-2 d-flex my-3">
            <ExportToExcel
              exportFunction={exportToCSV}
              apiData={dataLeaves}
              fileName={fileName}
            />
          </div>
        </div>
        <CCol
          xs={12}
          className="d-flex justify-content-end"
          //   style={{ border }}
        ></CCol>
        <DataTable
          // defaultSortField="name"
          striped
          pagination
          // paginationServer
          // paginationTotalRows={count}
          // subHeaderComponent={subHeaderComponent}
          // subHeader
          // onChangePage={handlePageChange}
          columns={columns}
          data={dataLeaves}
          // onChangeRowsPerPage={handlePerRowsChange}
          // paginationPerPage={pageSize}
          customStyles={customStyles}
        />
      </CCard>
      <CModal
        visible={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      >
        <CModalHeader>Confirm Delete</CModalHeader>
        <CModalBody>Are you sure you want to delete?</CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setDeleteModalOpen(false)}>
            Cancel
          </CButton>
          <CButton color="danger" onClick={handleDeleteConfirm}>
            Delete
          </CButton>
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default Leave;
