import { cilBook } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCard,
  CCol,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import axios from "axios";
import AttendanceTable from "components/ReportTable/AttendanceTable";
import ReportData from "components/ReportTable/ReportData";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import pic1 from "../assets/images/12.jpg";
import { useNavigate } from "react-router-dom";
import {
  checkInConfirmation,
  checkOutConfirmation,
  getAbsensi,
  middleDayConfirmation,
} from "apis/axios";
const Absensi = () => {
  let navigate = useNavigate();
  const [dataAttendance, setDataAttendance] = useState([]);
  const [attendanceId, setAttendanceId] = useState("");
  const [showDataPhoto, setShowDataPhoto] = useState("");
  const [cordinate, setCordinate] = useState("");
  const [path, setPath] = useState("");
  const [checkInApproved, setCheckInApproved] = useState(false);
  const [middleOfDayApproved, setMiddleOfDayApproved] = useState(false);
  const [checkOutApproved, setCheckOutApproved] = useState(false);
  const [showModalSiang, setShowModalSiang] = useState(false);
  const [showModalPulang, setShowModalPulang] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalLocation, setShowModalLocation] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1); // Tambahkan state currentPage
  const [totalPages, setTotalPages] = useState(1); // Tambahkan state totalPages
  const [count, setCount] = useState(0);
  const [sizePage, setSizePage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const handleSearchInputChange = (e) => {
    const input = e.target.value;
    setSearchInput(input);
    setPage(1);
    fetchData();
  };

  const fetchData = async () => {
    try {
      let queryParams = {
        page,
        limit: sizePage,
        startDate,
        endDate,
        month: selectedMonth,
        year: selectedYear,
      };

      if (searchInput) {
        queryParams.name = searchInput;
      }

      const response = await getAbsensi(queryParams);
      setDataAttendance(response.data.rows);
      setCurrentPage(response.data.page);
      setTotalPages(response.data.totalPages);
      setCount(response.data.count);
    } catch (error) {
      console.error(error);
    }
  };

  function formatDate(date) {
    if (!date) return ""; // Handle null or undefined date

    const dateObject = new Date(date);
    const day = String(dateObject.getDate()).padStart(2, "0");
    const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = dateObject.getFullYear();

    return `${day}-${month}-${year}`;
  }
  function formatTime(date) {
    const options = {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZone: "Asia/Jakarta",
    };

    const formattedTime = new Date(date).toLocaleString(
      "id-ID", // Indonesian locale
      options
    );

    return formattedTime;
  }

  const handleShowModal = (id) => {
    setShowModal(!showModal);
    setAttendanceId(id);
  };
  const handleShowModalSiang = (id) => {
    setShowModalSiang(!showModalSiang);
    setAttendanceId(id);
  };
  const handleShowModalPulang = (id) => {
    setShowModalPulang(!showModalPulang);
    setAttendanceId(id);
  };
  const handleShowModalPhoto = (value, cordinate, path) => {
    setShowDataPhoto(value);
    setCordinate(cordinate);
    setPath(path);
    setShowModalLocation(!showModalLocation);
  };
  // console.log(showDataPhoto)
  const handlePageChange = (page) => {
    setPage(page);
  };
  const handleRows = (perPage) => {
    setSizePage(perPage);
  };
  const handleCheckInConfirmation = async () => {
    try {
      // Kirim permintaan ke server untuk mengubah status check_in_approved menjadi true
      await checkInConfirmation(attendanceId);
      setCheckInApproved(true);
      setShowModal(!showModal);
      window.alert("Check-in confirmation successful");
      window.location.reload();
    } catch (error) {
      console.error("Error confirming check-in:", error);
    }
  };
  const handleMiddleDayConfirmation = async () => {
    try {
      // Kirim permintaan ke server untuk mengubah status check_in_approved menjadi true
      await middleDayConfirmation(attendanceId);
      setMiddleOfDayApproved(true);
      setShowModalSiang(!showModalSiang);
      alert("Sukses Konfirmasi Absen Siang");
      window.location.reload();
    } catch (error) {
      console.error("Error confirming middleofDay:", error);
    }
  };
  const handleCheckoutConfirmation = async () => {
    try {
      // Kirim permintaan ke server untuk mengubah status check_in_approved menjadi true
      await checkOutConfirmation(attendanceId);
      setCheckOutApproved(true);
      setShowModalPulang(!showModalPulang);
      alert("Sukses Konfirmasi Absen Pulang");
      window.location.reload();
    } catch (error) {
      console.error("Error confirming middleofDay:", error);
    }
  };

  const columns = [
    {
      name: "Tanggal",
      selector: (row) => formatDate(row.created_at),
      sortable: true,
    },
    {
      name: "Nama",
      center: false,
      wrap: true,
      width: "150px",
      selector: (row) =>
        row.user && row.user.name ? row.user.name : "Akun User Telah Dihapus",
      sortable: true,
    },
    {
      name: "Pagi",
      center: true,
      wrap: true,
      width: "120px",
      cell: (row) =>
        row.check_in ? (
          <>
            <p
              onClick={() => handleShowModal(row.id)}
              style={
                row.check_in_approved === false
                  ? { color: "red", cursor: "pointer" }
                  : { color: "green", cursor: "pointer" }
              }
              className="p-0 m-0"
            >
              {formatTime(row.check_in)}
            </p>
          </>
        ) : (
          "-"
        ),
      selector: (row) => formatTime(row.check_in),
      sortable: true,
    },
    {
      name: "Lokasi",
      center: true,
      wrap: true,
      width: "120px",
      cell: (row) =>
        row.check_in_cordinate ? (
          <>
            <p
              style={{ color: "#b38104", cursor: "pointer" }}
              className="p-0 m-0"
              onClick={() =>
                handleShowModalPhoto(
                  "Pagi",
                  row.check_in_cordinate,
                  row.attendance_photos[0]
                    ? `http://103.116.168.147:3030/${row.attendance_photos[0].path}`
                    : `http://103.116.168.147:3030/${row.attendance_photos[0].path}`
                )
              }
            >
              {row.check_in_location ? row.check_in_location : "-"}
            </p>
          </>
        ) : (
          "-"
        ),
      selector: (row) => row.check_in_location,
      sortable: true,
    },
    {
      name: "Siang",
      center: true,
      wrap: true,
      width: "120px",
      cell: (row) =>
        row.middle_of_day ? (
          <>
            <p
              onClick={() => handleShowModalSiang(row.id)}
              style={
                row.middle_of_day_approved === false
                  ? { color: "red", cursor: "pointer" }
                  : { color: "green", cursor: "pointer" }
              }
              className="p-0 m-0"
            >
              {formatTime(row.middle_of_day)}
            </p>
          </>
        ) : (
          "-"
        ),
      selector: (row) => formatTime(row.middle_of_day),
      sortable: true,
    },
    {
      name: "Lokasi",
      center: true,
      cell: (row) =>
        row.middle_of_day_cordinate ? (
          <>
            <p
              style={{ color: "#b38104", cursor: "pointer" }}
              className="p-0 m-0"
              onClick={() =>
                handleShowModalPhoto(
                  "Siang",
                  row.middle_of_day_cordinate,
                  row.check_in_cordinate === ""
                    ? `http://103.116.168.147:3030/${row.attendance_photos[0].path}`
                    : row.check_in_cordinate
                    ? `http://103.116.168.147:3030/${row.attendance_photos[1].path}`
                    : `http://103.116.168.147:3030/${row.attendance_photos[1].path}`
                )
              }
            >
              {row.middle_of_day_location}
            </p>
          </>
        ) : (
          "-"
        ),
      selector: (row) => row.middle_of_day_location,
      sortable: true,
    },

    {
      name: "Pulang",
      center: true,
      wrap: true,
      width: "120px",
      cell: (row) =>
        row.check_out ? (
          <>
            <p
              onClick={() => handleShowModalPulang(row.id)}
              style={
                row.check_out_approved === false
                  ? { color: "red", cursor: "pointer" }
                  : { color: "green", cursor: "pointer" }
              }
              className="p-0 m-0"
            >
              {formatTime(row.check_out)}
            </p>
          </>
        ) : (
          "-"
        ),
      selector: (row) => formatTime(row.check_out),
      sortable: true,
    },
    {
      name: "Lokasi",
      center: true,
      cell: (row) =>
        row.check_out_location ? (
          <>
            <p
              style={{ color: "#b38104", cursor: "pointer" }}
              className="p-0 m-0"
              onClick={() =>
                handleShowModalPhoto(
                  "Pulang",
                  row.check_out_cordinate,
                  row.check_in_cordinate === "" &&
                    row.middle_of_day_cordinate === ""
                    ? `http://103.116.168.147:3030/${row.attendance_photos[0].path}`
                    : row.check_in_cordinate === "" &&
                      row.middle_of_day_cordinate
                    ? `http://103.116.168.147:3030/${row.attendance_photos[1].path}`
                    : row.check_in_cordinate && row.middle_of_day_cordinate
                    ? `http://103.116.168.147:3030/${row.attendance_photos[2].path}`
                    : `http://103.116.168.147:3030/${row.attendance_photos[0].path}`
                )
              }
            >
              {row.check_out_location ? row.check_out_location : "-"}
            </p>
          </>
        ) : (
          "-"
        ),
      selector: (row) => row.check_out_location,
      sortable: true,
    },
  ];
  const customStyles = {
    rows: {
      style: {
        minHeight: "10px",
        padding: "20px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        backgroundColor: "#ffb703",
        color: "#fff",
        fontSize: "15px",
        fontWeight: "600",
      },
    },
    cells: {
      style: {
        padding: "5px",
        fontSize: "15px",
        fontWeight: "600",
      },
    },
  };

  useEffect(() => {
    const checkValid = async () => {
      const res = await localStorage.getItem("Token");
      if (res === null || res === undefined) {
        navigate("/login");
      } else {
        fetchData();
      }
    };

    checkValid();
  }, [navigate]);

  useEffect(() => {
    fetchData();
  }, [
    page,
    sizePage,
    startDate,
    endDate,
    selectedMonth,
    selectedYear,
    searchInput,
  ]);

  return (
    <div className="container">
      <CCard className="px-2 py-2">
        <h5 className="my-3" style={{ fontWeight: 800 }}>
          LAPORAN ABSENSI
        </h5>
        <CCol xs={12} className="flex-row d-flex gap-4 my-3">
          <div className="col-2">
            <label>Tanggal Awal:</label>
            <input
              className="form-control"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="col-2">
            <label>Tanggal Akhir:</label>
            <input
              className="form-control"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className="col-2">
            <label>Bulan :</label>
            <input
              className="form-control"
              type="number"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
            />
          </div>
          <div className="col-2">
            <label>Tahun :</label>
            <input
              className="form-control"
              type="year"
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
            />
          </div>
          <div className="col-2">
            <label>Cari Nama:</label>
            <input
              className="form-control"
              type="text"
              placeholder="Cari Nama"
              value={searchInput}
              onChange={handleSearchInputChange}
            />
          </div>
        </CCol>

        <DataTable
          striped
          pagination
          paginationServer
          paginationTotalRows={count}
          onChangePage={handlePageChange}
          columns={columns}
          data={dataAttendance}
          onChangeRowsPerPage={handleRows}
          paginationPerPage={sizePage}
          customStyles={customStyles}
        />
      </CCard>
      <CModal onClose={() => setShowModal(false)} visible={showModal}>
        <CModalHeader>
          <CModalTitle>Verifikasi Absensi</CModalTitle>
        </CModalHeader>
        <CModalBody className="d-flex col-12 justify-content-center align-items-center">
          <div className="col-6">
            <CButton
              style={{ width: "100%", color: "white" }}
              color="success"
              onClick={handleCheckInConfirmation}
            >
              KONFIRMASI
            </CButton>
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setShowModal(false)}>
            Close
          </CButton>
        </CModalFooter>
      </CModal>
      <CModal onClose={() => setShowModalSiang(false)} visible={showModalSiang}>
        <CModalHeader>
          <CModalTitle>Verifikasi Absensi</CModalTitle>
        </CModalHeader>
        <CModalBody className="d-flex col-12 justify-content-center align-items-center">
          <div className="col-6">
            <CButton
              style={{ width: "100%", color: "white" }}
              color="success"
              onClick={handleMiddleDayConfirmation}
            >
              KONFIRMASI
            </CButton>
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setShowModalSiang(false)}>
            Close
          </CButton>
        </CModalFooter>
      </CModal>
      <CModal
        onClose={() => setShowModalPulang(false)}
        visible={showModalPulang}
      >
        <CModalHeader>
          <CModalTitle>Verifikasi Absensi</CModalTitle>
        </CModalHeader>
        <CModalBody className="d-flex col-12 justify-content-center align-items-center">
          <div className="col-6">
            <CButton
              style={{ width: "100%", color: "white" }}
              color="success"
              onClick={handleCheckoutConfirmation}
            >
              KONFIRMASI
            </CButton>
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setShowModalPulang(false)}>
            Close
          </CButton>
        </CModalFooter>
      </CModal>
      <CModal
        onClose={() => setShowModalLocation(false)}
        visible={showModalLocation}
      >
        <CModalHeader>
          <CModalTitle>Verifikasi Absensi</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div
            className="p-3"
            style={{ border: "1px solid grey", borderRadius: 10 }}
          >
            {showDataPhoto === "Pagi" ? (
              <>
                <h5 className="p-0 m-0">Absen Pagi</h5>
                <div>
                  <p className="p-0 m-0" style={{ fontWeight: "bold" }}>
                    Koordinat : {cordinate}
                  </p>
                  <span className="p-0 m-0">
                    <img
                      src={path}
                      alt="Photo Absen Pagi"
                      style={{ width: "100%", height: "50%" }}
                    />
                  </span>
                </div>
              </>
            ) : showDataPhoto === "Siang" ? (
              <>
                <h5 className="p-0 m-0">Absen Siang</h5>
                <div>
                  <p className="p-0 m-0" style={{ fontWeight: "bold" }}>
                    Koordinat : {cordinate}
                  </p>
                  <span className="p-0 m-0">
                    <img
                      src={path}
                      alt="Photo Absen Siang"
                      style={{ width: "100%", height: "50%" }}
                    />
                  </span>
                </div>
              </>
            ) : showDataPhoto === "Pulang" ? (
              <>
                <h5 className="p-0 m-0">Absen Pulang</h5>
                <div>
                  <p className="p-0 m-0" style={{ fontWeight: "bold" }}>
                    Koordinat : {cordinate}
                  </p>
                  <span className="p-0 m-0">
                    <img
                      src={path}
                      alt="Photo Absen Pulang"
                      style={{ width: "100%", height: "50%" }}
                    />
                  </span>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setShowModal(false)}>
            Close
          </CButton>
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default Absensi;
