import {
  CButton,
  CCard,
  CCol,
  CForm,
  CFormInput,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { createHolidays, getHolidays } from "apis/axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import DataTable from "react-data-table-component";

const Holidays = () => {
  const [dataHolidays, setDataHolidays] = useState();
  const [visible, setVisible] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    date: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await createHolidays(formData).then((res) => {
      //   console.log(res);
      if (res.status === 201) {
        window.alert("Data berhasil ditambahkan!");
        window.location.reload();
      } else {
        alert("Data Liburan Gagal Ditambahkan");
      }
    });
    // Panggil fungsi untuk mengirim data ke API di sini
    // createHolidays(formData);
  };

  const getDataHolidays = async () => {
    await getHolidays()
      .then(async (res) => {
        // setDataHolidays(res.res.res);
        setDataHolidays(res.data.data);
      })
      .catch((error) => {
        // Tangani error di sini
        console.error(error);
      });
  };
  function formatDate(date) {
    if (!date) return ""; // Handle null or undefined date

    const dateObject = new Date(date);
    const day = String(dateObject.getDate()).padStart(2, "0");
    const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = dateObject.getFullYear();

    return `${day}-${month}-${year}`;
  }
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      width: "50px",
    },
    {
      name: "Nama",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Tanggal",
      selector: (row) => formatDate(row.date),
      sortable: true,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "10px",
        padding: "20px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        backgroundColor: "#ffb703",
        color: "#fff",
        fontSize: "15px",
        fontWeight: "600",
      },
    },
    cells: {
      style: {
        padding: "5px",
        fontSize: "15px",
        fontWeight: "600",
      },
    },
  };

  useEffect(() => {
    getDataHolidays();
  }, []);

  return (
    <div className="container">
      <CCard className="px-2 py-2">
        <h5 className="my-3" style={{ fontWeight: 800 }}>
          DATA HARI LIBUR
        </h5>
        <CCol
          xs={12}
          className="d-flex justify-content-end"
          //   style={{ border }}
        >
          <CCol xs={2} className="d-flex justify-content-end mb-3">
            <CButton
              color="warning"
              style={{ color: "white" }}
              onClick={() => setVisible(!visible)}
            >
              Tambah Data
            </CButton>
          </CCol>
        </CCol>
        <DataTable
          // defaultSortField="name"
          striped
          pagination
          // paginationServer
          // paginationTotalRows={count}
          // subHeaderComponent={subHeaderComponent}
          // subHeader
          // onChangePage={handlePageChange}
          columns={columns}
          data={dataHolidays}
          // onChangeRowsPerPage={handlePerRowsChange}
          // paginationPerPage={pageSize}
          customStyles={customStyles}
        />
      </CCard>
      <CModal visible={visible} onClose={() => setVisible(false)}>
        <CModalHeader onClose={() => setVisible(false)}>
          <CModalTitle>Modal title</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CForm className="d-flex flex-column gap-3">
            <CCol>
              <label>Tanggal :</label>
              <CFormInput
                type="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
              ></CFormInput>
            </CCol>
            <CCol>
              <label>Nama Hari :</label>
              <CFormInput
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
              ></CFormInput>
            </CCol>
          </CForm>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            Close
          </CButton>
          <CButton color="primary" onClick={handleSubmit}>
            Save changes
          </CButton>
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default Holidays;
