import React from "react";
import Absensi from "./views/Absensi";
import AttendanceRecord from "views/AttendanceRecord";
import Holidays from "views/Holidays";
import Employee from "views/Employee";
import Leave from "views/Leave";
import Location from "views/Location";

const routes = [
  { path: "/", exact: true, name: "Home" },
  { path: "/", name: "Absensi", element: Absensi },
  { path: "/data", name: "Attendance Record", element: AttendanceRecord },
  { path: "/holidays", name: "Holidays Data", element: Holidays },
  { path: "/employee", name: "Employee Data", element: Employee },
  { path: "/leave", name: "Employee Data", element: Leave },
  { path: "/location", name: "Location Data", element: Location },
  // { path: "/reportdata", name: "Report Data", element: ReportData },
];

export default routes;
