import React from "react";
import { AppContent, AppHeader, AppSidebar } from "../components";
import { useNavigate } from "react-router-dom";

const DefaultLayout = () => {
  let navigate = useNavigate();


  const logout = async () => {
    await localStorage.clear();
    navigate("/login");
  };
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader action={()=>logout()}/>
        <div className="body flex-grow-1">
          <AppContent />
        </div>
        {/* <AppFooter /> */}
      </div>
    </div>
  );
};

export default DefaultLayout;
